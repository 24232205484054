import { Auth } from 'aws-amplify';
import { LoginOptions, MFAType, UserAttributes, NewPasswordFields, EventList } from '../types/api.types';
import jwt_decode from 'jwt-decode';
import 'cookie-store';


const AuthenticationMethods: any = {

    refreshCredentialTimeout: null,
    refreshingCredentialPromise: null,

    Auth,

    getEndpoint: function() {
        return this.AppConfig.awsexport.AppSync.Default.ApiUrl;
    },

    setClient: async function(user?: any) {

        let { createAppSyncApolloClient }  = await import('../utils/connections/AppSync');

        if (!user) user = this.authUserObject;
        if (!this.authUserObject) this.authUserObject = user;

        const currentSession = await this.refreshCredentials();

        this.client = await createAppSyncApolloClient({
            appSyncApiUrl: this.AppConfig.awsexport.AppSync.Default.ApiUrl,
            realtimeApiUrl: this.AppConfig.awsexport.AppSync.Default.RealtimeApiUrl,
            getJwtToken: () => user.signInUserSession.idToken.jwtToken,
            cacheConfig: null,
            connectToDevTools: true
        });


        if( !this.dgClient && this.AppConfig.dgraph?.token){

            let { createDGraphApolloClient } = await import(`../utils/connections/DGraph`)

            const accessToken = currentSession?.accessToken?.jwtToken ?? (await this.getSession()).accessToken?.jwtToken

            if( !accessToken ){
                console.error("🛑 No access token yet..")
                return
            }

            // get NG User ID first
            const ngUser = await this.getCurrentUser()

            const ngUserID = ngUser.id
            const ngUserProfileID = ngUser.userID // this field is going to be deprecated in favour of only using ngUser.id


            // Request a JWT for dgraph from the ng-data-layer microservice, then create an Apollo client with it.
            fetch(`/dg/getToken?ngUserId=${ngUserID}&ngPersonaId=${ngUserProfileID}`, {
                cache: "no-cache",
                headers: {
                    'x-access-token': accessToken
                }
            })
                .then(response => response.json())
                .then(async (data) => {

                    if( !data?.dgJWT?.accessToken || !data?.authJWT ){
                        console.error("🛑 Could not get DG token - Invalid response..")
                        return
                    }

                    this.dgClient = await createDGraphApolloClient({
                        httpsEndpoint: `https://${window.location.hostname}/dgql`
                        ,wssEndpoint: `wss://${window.location.hostname}/dgql`
                        ,authToken: data?.dgJWT?.accessToken ?? ""
                        ,idToken: data?.authJWT ?? ""
                        ,cacheConfig: null
                    })

                    console.log(`✅ DG Client configured..`)

                }).catch((err) => {

                    setTimeout(async () => {
                        await this.setClient(user)
                    }, 3000)

                });

        } 

        if( !this.gqlClient && !this.AppConfig.NGDataLayer?.disabled){

            let { createNGDataLayerApolloClient } = await import(`../utils/connections/NGDataLayer`)

            const accessToken = currentSession?.accessToken?.jwtToken ?? (await this.getSession()).accessToken?.jwtToken

            if( !accessToken ){
                console.error("🛑 No access token yet..")
                return
            }

            this.gqlClient = await createNGDataLayerApolloClient({
                httpsEndpoint: `https://${window.location.hostname}/gql`
                ,wssEndpoint: `wss://${window.location.hostname}/gql`
                ,authToken: accessToken
                ,cacheConfig: null
            })

            console.log(`✅ NG Data layer Client configured..`)

        } 

        return true;
    },

    
    login: async function(username: string, password: string, options: LoginOptions = {}): Promise<any> {

        if (!('setSignedIn' in options)) options.setSignedIn = true;
        username = username.toLowerCase();

        let profile = null;

        try {
            let user = await Auth.signIn(username, password),
                required = [],
                obj: any = {};
            
            this.parent.setState({ isSSO: false });
            this.authUserObject = user;

            if (user && user.signInUserSession) {
                if ('returnProfileValues' in options && options.returnProfileValues.length) {
                    profile = await this.getCurrentUserProfile(options.returnProfileValues);
                }
            }

            if ('challengeParam' in user && 'requiredAttributes' in user.challengeParam) {
                required = user.challengeParam.requiredAttributes;
            }

            if (user) {

                obj = {
                    user: {
                        username,
                        ...user.attributes,
                        jwtToken: (user.signInUserSession) ? user.signInUserSession.idToken.jwtToken : null
                    },
                    profile
                }

                if ('challengeName' in user) {
                    obj.challengeName = user.challengeName;
                    obj.requiredFields = required;
                    return obj
                } else {
                    if (options.setSignedIn) {
                        this.setUserSignedIn(user)
                    }
                    return obj;
                }
            }
        } catch (e) {
            console.error({ code: e.code, message: e.message, username, profile });
            throw new Error(e.message);
        }
    },

    SSOLogin: async function(provider: any = null) {

        if ('oauth' in this.AppConfig) {

            try {
                if (provider) {
                    await Auth.federatedSignIn({ provider });
                } else {
                    await Auth.federatedSignIn();
                }
            } catch (e: any) {
                console.error(`SSO Login error: ${e.message ?? ""}`)
                throw e
            }

        } else {

            throw new Error("Must specify oauth provider in player config")

        }
    },

    checkUserAuth: async function(returnUser: boolean = false, signIn: boolean = false) {
        try {
            let user = await Auth.currentAuthenticatedUser({
                bypassCache: true  // gets latest user data
            });

            if (user) {
                if(!this.client) {
                    await this.setClient(user);
                } else {
                    this.refreshCredentials();
                }
            }

            if (signIn && user) {
                this.setUserSignedIn(user);
            }

            if (user && returnUser) return { username: user.username, ...user.attributes, jwtToken: user.signInUserSession.idToken.jwtToken };
            else return !!user;

        } catch (e) {
            return false;
        }

    },

    getSession: async function() {
        return await Auth.currentSession();
    },

    confirmLogin: async function(code: string, mfaType: MFAType | null | undefined) {
        try {
            let loggedUser = await Auth.confirmSignIn(
                this.authUserObject,
                code,
                mfaType
            );

            await this.getCurrentUserProfile(['username']);

            this.setUserSignedIn(loggedUser);
        } catch (e) {
            throw new Error(e.message);
        }
    },

    createAccount: async function(username: string, password: string, attributes: { [key: string]: string }) {

        if (!attributes) attributes = {};

        try {

            let newUser = await Auth.signUp({
                username: username.toLowerCase(),
                password,
                attributes
            });

            if (newUser) {
                if (newUser.userConfirmed === false) {
                    return { userConfirmed: newUser.userConfirmed, codeDelivery: newUser.codeDeliveryDetails, userSub: newUser.userSub, username };
                } else {
                    return { userConfirmed: newUser.userConfirmed, userSub: newUser.userSub, username };
                }
            }
        } catch (e) {
            throw new Error(e.message);
        }
    },



    confirmCreate: async function(username: string, code: string) {
        try {
            await Auth.confirmSignUp(
                username.toLowerCase(),
                code
            );
            return true;
        } catch (e) {
            throw new Error(e.message);
        }
    },




    resendCreateCode: async function(username: string) {
        try {
            await Auth.resendSignUp(username.toLowerCase());
        } catch (e) {
            throw new Error(e.message);
        }
    },




    completeNewPassword: async function(newPassword: string, fields: NewPasswordFields) {
        try {
            let complete = await Auth.completeNewPassword(
                this.authUserObject,
                newPassword,
                fields
            );

            if (complete && complete.signInUserSession && (!this.client || !this.ngClient) ) {
                this.setClient(complete);
            }

            if (complete.challengeName) {
                return { challengeName: complete.challengeName, username: this.authUserObject.username };
            } else {
                this.setUserSignedIn(complete);
            }
        } catch (e) {
            throw new Error(e.message);
        }
    },



    forgotPassword: async function(username: string) {
        try {
            await Auth.forgotPassword(username.toLowerCase());
            return { username };
        } catch (e) {
            throw new Error(e.message);
        }

    },


    forgotPasswordSubmit: async function(username: string, code: string, newPassword: string) {

        try {
            await Auth.forgotPasswordSubmit(username.toLowerCase(), code, newPassword);
            return true
        } catch (e) {
            throw new Error(e.message);
        }
    },



    updateUserAttributes: async function(attributes: UserAttributes) {
        try {
            let user = await Auth.currentAuthenticatedUser({ bypassCache: true });
            let result = await Auth.updateUserAttributes(user, attributes);
            user = await Auth.currentAuthenticatedUser({ bypassCache: true });
            return { updateUserAttributes: result, currentUser: user.attributes };
        } catch (e) {
            throw new Error(e.message);
        }
    },


    getCurrentCognitoUser: async function(addKeys?: string[]) {
        try {
            let user = await Auth.currentAuthenticatedUser({ bypassCache: true });

            if (!this.client || !this.ngClient) this.setClient(user);
            let obj = 'attributes' in user ? user.attributes : {};
            obj.username = user.username;

            if (addKeys) {
                for (var a in addKeys) obj[addKeys[a]] = user[addKeys[a]];
            }

            return obj;
        } catch (e) {
            throw new Error(e.message);
        }
    },

    getFullCognitoUser: async function() {
        return await Auth.currentAuthenticatedUser({ bypassCache: true });
    },

    refreshCredentials: async function() {
        
        if( this.refreshingCredentialPromise != null && !this.refreshingCredentialPromise.fu ){
            return this.refreshingCredentialPromise
        }

        this.refreshingCredentialPromise = new Promise((resolve, reject) => {

            // If the user is not logged in, we can't refresh anything
            if(!this.authUserObject || !this.isLoggedIn()) {

                setTimeout(() => {
                    this.refreshingCredentialPromise = null
                },200)

                resolve(null)

                return
            } 

            this.authUserObject.getSession(async (err: any, s: any) => {
                
                if (err) {

                    console.error(`Rejected refresh: ${err}`)

                    await this.logout();

                    setTimeout(() => {
                        this.refreshingCredentialPromise = null
                    },200)

                    reject(err)

                } else {

                    document.cookie = `access_token=${s.accessToken.jwtToken};secure;path=/`;

                    setTimeout(() => {
                        this.refreshingCredentialPromise = null
                    },200)
                    
                    resolve(s)

                }

            });

        })

        return this.refreshingCredentialPromise

    },

    setUserSignedIn: async function (user?: any) {
        let data;
        if (!user) {
            data = await Auth.currentAuthenticatedUser({ bypassCache: true });
        } else {
            data = user;
        }

        this.parent.props.main.setState({ signedIn: true, authData: data, loading: false });
    },


    setUserSignedOut: async function(redirectPath: string = "/") {
        localStorage.clear();
        this.parent.props.main.setState({ signedIn: false, authData: {}, isSSO: false }, () => {
            if(redirectPath !== null) this.navigate(redirectPath);
        })
    },

    logout: async function (setSignedOut: boolean = true, redirectPath: string = "/") {
        this.closeAllLightboxes();

        this.dgClient?.stop();
        this.client?.stop();
        this.gqlClient?.stop();
        
        this.client = null;
        this.dgClient = null;
        this.gqlClient = null;

        this.authUserObject = null;
        this.refreshingCredentialPromise = null;

        // clear out user objects
        this.NGUser = {};
        this.NGPersFDLUser = {};

        // clear out subs
        this.fdlsubs = [];
        this.ngusrsubs = [];
        
        await this.clearAllSessionData();

        if (setSignedOut) {
            this.setUserSignedOut(redirectPath);
        }

    },

    clearAllCookies: async function() {

        window.cookieStore?.getAll().then(cookies => cookies.forEach(cookie => {
            console.log(`deleting ${cookie.name}`)
            window.cookieStore?.delete(cookie.name);
        }));

    },

    clearAllSessionData: async function() {

        await Auth.signOut();
        
        // This is a workaround to force the cognito cookies to be cleared.
        // cognito cookies are set from a different domain, so they can't be cleared by `clearAllCookies`.
        await (Auth as any)._storage?.clear();

        this.clearAllCookies();
    },

    getCurrentUserData: async function() {
        return await Auth.currentUserInfo();
    },


    CognitoLogin: async function(token: any, identityConfig: any) {
        let { CognitoIdentityServiceProvider, config } = await import('aws-sdk');
        let AmazonCognitoIdentity = await import('amazon-cognito-identity-js');

        config.region = identityConfig.region;
        let provider = new CognitoIdentityServiceProvider();
        let AuthParameters = await this.parseRefreshToken(token);

        let init = provider.initiateAuth({
            AuthFlow: "REFRESH_TOKEN_AUTH",
            ClientId: identityConfig.userPoolWebClientId,
            AuthParameters
        });        
        
        init.send((err: any, data: any) => {

            if(err) {
                // delete cookies
                this.clearAllCookies();

                console.error(err);
                return;
            }
            let session = new AmazonCognitoIdentity.CognitoUserSession({
                IdToken: new AmazonCognitoIdentity.CognitoIdToken({IdToken: data.AuthenticationResult.IdToken}),
                AccessToken: new AmazonCognitoIdentity.CognitoAccessToken({AccessToken: data.AuthenticationResult.AccessToken}),
                RefreshToken: new AmazonCognitoIdentity.CognitoRefreshToken({RefreshToken: AuthParameters.REFRESH_TOKEN})
            });


            let obj: any = jwt_decode(session.getIdToken().getJwtToken());
            var poolData = { 
                UserPoolId : identityConfig.userPoolId,
                ClientId : identityConfig.userPoolWebClientId
            };
            var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            var cognitoUser: any = new AmazonCognitoIdentity.CognitoUser({
                Username: obj['cognito:username'],
                Pool: userPool
            });

            if("DEVICE_KEY" in AuthParameters) {
                // might need to add in the deviceGroup and randomPassword
                cognitoUser.deviceKey = AuthParameters.DEVICE_KEY;
                cognitoUser.cacheDeviceKeyAndPassword();
            }

            cognitoUser.setSignInUserSession(session);

            this.removeQueryParam('fdapptoken');
            
            this.callEvent(EventList.SIGNIN, cognitoUser);
        })
        
    },

    MagicLinkLogin: async function(challenge: string) {
        try {
            const [email, code] = atob(challenge).split(',');
            const user = await Auth.signIn(email);
            console.log('user', user);
            let cca = await Auth.sendCustomChallengeAnswer(user, code);
            console.log('cca', cca);
            let cur_sess = await Auth.currentSession();
            console.log('cur_sess', cur_sess);
            this.setUserSignedIn(user);
            this.removeQueryParam('ml');
        } catch(e) {
            console.error({ code: e.code, message: e.message });
            throw new Error(e.message);
            // this.callEvent(EventList.ERROR, {message: e.message})
        }
    },

    parseRefreshToken: async function(token: string) {
        let AuthParameters: any = {
                REFRESH_TOKEN: atob(token)
        }

        try {
            if(AuthParameters.REFRESH_TOKEN.indexOf('{') !== -1) {
                let rt = JSON.parse(AuthParameters.REFRESH_TOKEN);
                if(typeof rt === 'object') {
                    if('device_key' in rt && rt.device_key !== null) {
                        AuthParameters.DEVICE_KEY = rt.device_key;
                    }
                    AuthParameters.REFRESH_TOKEN = rt.token;
                }
            }
        } catch(e) {
            console.error(e);
        }

        return AuthParameters;
    },

    buildRefreshToken: async function () {
        let session = await this.getSession();
        let refreshToken = {
            token: session.getRefreshToken().token,
            device_key: 'device_key' in session.accessToken.payload ? session.accessToken.payload.device_key : null
        }
        return btoa(JSON.stringify(refreshToken));
    },

    ssoGatewayLogin: async function(user: any, options: any = {}) {
        if(!user) user = await this.checkUserAuth();
        if(!!user) {
            let ssogateway = this.getQueryParam('ssoGatewayReq', options.search);
            let ssogatewaycb = this.getQueryParam('ssoGatewayCallback', options.search);
            if(ssogateway === 'true') {
                if(ssogatewaycb) {
                    let session = await this.getSession();
                    let refreshToken = await this.buildRefreshToken();
                    
                    this.navigate(`${ssogatewaycb}?idToken=${session.getIdToken().jwtToken}&refreshToken=${refreshToken}`, ssogatewaycb.match('^http(s)?://') !== null);
                    return;
                }
            } else {
                if(!this.parent.state.signedIn) {
                    this.setUserSignedIn(user);
                }
            }
        }
    }
}

export default AuthenticationMethods;
