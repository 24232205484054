import Promise from './promise';
export default class FDBridge {

    private ngready: any = null;
    public parent: any = null;


    public generateID = () => {
        return Math.random().toString(36).substr(2, 9)
    };

    public listeners: Function = () => {};
    public initValues: Function = () => {};
    public set: Function = () => {};
    public listen: Function = () => {};

    private getCurrentUserObject: Function = async () => {
        let userProfile;
        let username = '';
        if(!('NGUsr' in window.nexgen.AppConfig) || window.nexgen.AppConfig.NGUsr === true) {
            userProfile = await window.nexgen.getCurrentUser('fdl');
            username = userProfile.attFDLUser.username;
        } else {
            userProfile = await window.nexgen.getCurrentNGRProfile(['fdrURI', 'cognitoUsername']);
            username = userProfile.fdiURI || userProfile.cognitoUsername;
        }
        return { profile: userProfile, username};
    }

    
    public navigationGoToPath: Function = async (url: string) => {
        if(window.nexgen.inLegacyApp()) {
            this.readynow("navigation.goToPath", {path: url})
        } else {
            if(url === '..') {
                return await this.navigationExitItem();
            } else if('poc-generic-navigation' in window.nexgen.AppConfig.moduleIndex) {
                return await window.nexgen.navigate(`/poc-generic-navigation/all/#/poc-generic-navigation.all/` + url);
            } else {
                return await window.nexgen.navigate(url);
            }
        }
    };

    public readynow: Function = async (method: string, args: any = {}) => {
        // window.parent.postMessage({
        window.parent.parent.postMessage({
            method,
            args
        }, "*");
    };
    

    public navigationExitItem: Function = async () => {
        if(window.nexgen.inLegacyApp()) {
            this.readynow("navigation.exitItem");
        } else {
            if(window.nexgen.parent.state.frames.length > 0) {
                return await window.nexgen.closeAllLightboxes();
            } else {
                return await window.nexgen.navigate('back');
            }
        }
    };

    public systemHandleLink: Function = async (url: string) => {
        if(window.nexgen.inLegacyApp()) {
            this.readynow("system.handleUrl", {url});
        } else {
            return await window.nexgen.navigate(url, true, true);
        }
    };
    

    public dataGetStoreAsDictonary: Function = () => {
        let promise: any = new Promise();
        (async () => {
            let config = await window.nexgen.getConfig(this.ngready);
            promise.resolve(config);
        })();
        return promise;
    };
    public dataGetValueAsString: Function = (key: string) => {
        let promise: any = new Promise();
        (async () => {
            if(key === 'user') {
                if(!('NGUsr' in window.nexgen.AppConfig) || window.nexgen.AppConfig.NGUsr === true) {
                    let user = await window.nexgen.getCurrentUser('fdl');
                    promise.resolve(user ? user.attFDLUser.username : null);
                } else {
                    let user = await window.nexgen.getCurrentNGRProfile(['fdrURI']);
                    promise.resolve(user ? user.fdrURI : null);
                }
            } else {
                let config = await window.nexgen.getConfig(this.ngready);
                promise.resolve(key in config ? config[key] : null);
            }
        })();
        return promise;
    };

    public metaGetApiVersion: Function = async () => {
        return "1.0.0";
    };
    public systemGetAppVersion: Function = async () => {
        return "1.0.0";
    };
    public keyedValuesGetByScope: Function = (scope: string) => {
        let promise: any = new Promise();
        (async () => {
            let result = await fetch("/legacy-realtime/rts-api/KeyedValues/find", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                keyScope: scope
            })
        })
            promise.resolve(result);
        })();
        return promise;
        
    };
    public keyedValueGet: Function = (key: string) => {
        let promise: any = new Promise();
        (async () => {
            let result = await fetch("/legacy-realtime/rts-api/KeyedValues/find", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    key
                })
            })
            promise.resolve(result);
        })();
        return promise;
    };
    public keyedValueSet: Function = (key: string, data: string) => {
        let promise: any = new Promise();
        (async () => {
            try {
                let partition = window.nexgen.getPartition();
                let result = await fetch("/legacy-realtime/rts-api/KeyedValues/upsert", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        key,
                        partition,
                        value: data
                    })
                })
                promise.resolve(result);
            } catch(e) {
                console.log(e);
            }
        })();
        return promise;
    };

    public messageGetMediaBaseUrl: Function = () => {};
    public appGetPartitionName: Function = () => {
        let promise: any = new Promise();
        (async () => {
            promise.resolve(window.nexgen.getPartition());
        })();
        return promise
    };


    public userProfileGetCurrent: Function = () => {
        let promise: any = new Promise();
        (async () => {
            let obj = await this.getCurrentUserObject();
            let profile = 'attFDLUser' in obj.profile ? {
                ...obj.profile.attFDLUser,
                app_username: obj.profile.attFDLUser.username,
                emailAddress: obj.profile.attFDLUser.email
            } : {
                ...obj.profile
            }
            promise.resolve(profile);
        })();
        return promise
    };

    public userProfileGetById: Function = () => {};
    public userProfileGetByUsername: Function = () => {};
    public pdfLoadViewWithItem: Function = (item: string) => {
        window.nexgen.openLightbox({
            module: 'ngr-pdf-engine',
            id: 'legacy-pdf',
            params: {
                url: '/' + item
            },
            styles: {
                background: {
                    background: "#000",
                    opacity: 0.5
                },
                box: {}
            },
            options: {
                closeButton: true
            }
        })
    };
    public navigationPresentProfilePopup: Function = () => {
        window.nexgen.openProfile({
            module: 'ngr-profile'
        })
    };
    
    public userDataSave: Function = async (data: any) => {
        let userData = await this.getCurrentUserObject();
        let partition = window.nexgen.getPartition();
        let variables: any = {
            input: {
                user: userData.username,
                navLabelPath: "",
                activity: "",
                partition,
                ...data
            }
        }

        if(!partition) {
            return null;
        }

        let result = await window.nexgen.run(`
            mutation createFDRSUserDataGeneric(
                $input: CreateFDRSUserDataGeneric!
            ){
                createFDRSUserDataGeneric(input: $input) {
                    data,
                    id,
                    partition,
                    navLabelPath
                }
            }`, 
            {
                variables
            });
        
        return !!result;
    }

    public userDataGet: Function = async (data: any) => {
        let userData = await this.getCurrentUserObject();
        let partition = window.nexgen.getPartition();
        let variables: any = {
            user: userData.username,
            partition,
            ...data
        }

        if(!partition) {
            return null;
        }

        let result = await window.nexgen.run(`
            query listFDRSUserDataGeneric(
                $user: String
                $partition: String
                $item: String
                $navLabelPath: String
            ){
                listFDRSUserDataGeneric(user: $user, partition: $partition, item: $item, navLabelPath: $navLabelPath) {
                    data,
                    id,
                    partition,
                    navLabelPath
                }
            }`, 
            {
                variables,
                fetchPolicy: "network-only"
            });
        return result
    };


    public userDataSaveTextInput: Function = (key: string, data: {text: string}) => {
        let promise: any = new Promise();
        (async () => {
            let inputData: any = {
                item: key,
                itemType: "TEXTINPUT",
                data: data.text,
                navLabelPath: window.nexgen.getCurrentLocation().pathname
            }
            try {
                let result = await this.userDataSave(inputData);
                promise.resolve(result);
            } catch(e) {
                promise.reject(false);
            }
        })();
        return promise;
    };
    public userDataGetTextInput: Function = (item_name: string, base_path?: string) => {
        let promise: any = new Promise();
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "TEXTINPUT"
            },
                result = [];

            if(base_path) inputData.navLabelPath = base_path;

            try {
                result = await this.userDataGet(inputData);
            } catch(e) {
                promise.reject();
                return;
            }

            if(!result || result.length === 0) {
                promise.reject()
                return;
            } else {
                promise.resolve({
                    text: result[0].data
                });
                return;
            }
            
        })();
        return promise;
    };
    public userDataSaveObject: Function = (item_type: string, item_name: string, item_data: any) => {
        let promise: any = new Promise();
        if(!item_data) {
            // so the first argument isn't required
            item_data = item_name;
            item_name = item_type;
        }
        (async () => {
            // not really sure what item_type is used for, but with nexgen itemType is an enum
            let inputData: any = {
                item: item_name,
                itemType: "OBJECT",
                data: JSON.stringify(item_data)
            }
            try {
                let result = await this.userDataSave(inputData);
                promise.resolve(result);
            } catch(e) {
                promise.reject(false)
            }
        })();
        return promise;
    };
    public userDataGetObject: Function = (item_type: string, item_name: string | null) => {
        let promise: any = new Promise();
        if(!item_name) {
            // so the first argument isn't required
            item_name = item_type;
        }
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "OBJECT"
            }
            let result = await this.userDataGet(inputData);
            if(result.length === 0) {
                promise.reject(null);
            } else {
                promise.resolve(JSON.parse(result[0].data));
            }
        })();
        return promise;
    };

    public userDataSaveRanking: Function = (item_name: string, item_data: {ranks: {rank: number, answer: string}[]}) => {
        console.error('userDataSaveRanking is not currently functional');
        return {};
    }

    public userDataGetRanking: Function = (item_name: string, item_path?: string) => {
        console.error('userDataGetRanking is not currently functional');
        return {};
    }

    public userDataSaveRating: Function = ( item_name: string, item_data: {rating: number}) => {
        let promise: any = new Promise();
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "RATING",
                data: item_data.rating,
                navLabelPath: window.nexgen.getCurrentLocation().pathname
            }
            try {
                let result = await this.userDataSave(inputData);
                promise.resolve(result);
            } catch(e) {
                promise.reject(false);
            }
        })();
        return promise;
    }

    public userDataGetRating: Function = (item_name: string, item_path?: string) => {
        let promise: any = new Promise();
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "RATING"
            }
            if(item_path) inputData.navLabelPath = item_path;

            let result = await this.userDataGet(inputData);
            if(result.length === 0) {
                promise.reject(null);
            } else {
                promise.resolve({rating: parseInt(result[0].data) });
            }
        })();
        return promise;
    }

    public userDataSaveMultipleChoice: Function = ( item_name: string, item_data: {choices: {choice: number | string}[]}) => {
        let promise: any = new Promise();
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "MULTIPLECHOICE",
                data: item_data.choices.map(c => c.choice).join(','),
                navLabelPath: window.nexgen.getCurrentLocation().pathname
            }
            try {
                let result = await this.userDataSave(inputData);
                promise.resolve(result);
            } catch(e) {
                promise.reject(false);
            }
        })();
        return promise;
    }

    public userDataGetMultipleChoice: Function = (item_name: string, item_path?: string) => {
        let promise: any = new Promise();
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "MULTIPLECHOICE"
            }
            if(item_path) inputData.navLabelPath = item_path;

            let result = await this.userDataGet(inputData);
            if(result.length === 0) {
                promise.reject(null);
            } else {
                promise.resolve({choices: result[0].data.split(',').map((d: string | number) => ({choice: d})) });
            }
        })();
        return promise;
    }

    public userDataSaveNumeric: Function = ( item_name: string, item_data: {number: number}) => {
        let promise: any = new Promise();
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "NUMERIC",
                data: item_data.number,
                navLabelPath: window.nexgen.getCurrentLocation().pathname
            }
            try {
                let result = await this.userDataSave(inputData);
                promise.resolve(result);
            } catch(e) {
                promise.reject(false);
            }
        })();
        return promise;
    }

    public userDataGetNumeric: Function = (item_name: string, item_path?: string) => {
        let promise: any = new Promise();
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "NUMERIC"
            }
            if(item_path) inputData.navLabelPath = item_path;

            let result = await this.userDataGet(inputData);
            if(result.length === 0) {
                promise.reject(null);
            } else {
                promise.resolve({number: parseInt(result[0].data) });
            }
        })();
        return promise;
    }

    public itemDataGetSelf: Function = () => {
        let promise: any = new Promise();
        (async () => {
            promise.resolve(null);
        })();
        return promise;
    }

    constructor(ngready: any = null) {
        this.ngready = ngready;
    }
}