import * as colors from 'twind/colors';
import { forms } from '@twind/forms';
import { aspectRatio } from '@twind/aspect-ratio';
import typography from '@twind/typography';
import { lineClamp } from '@twind/line-clamp';


// default theme config
const twconfig = {
    theme: {
        colors: {
            black: colors.black,
            white: colors.white,
            gray: colors.trueGray,
            primary: {
                50: 'var(--primary-50)',
                100: 'var(--primary-100)',
                200: 'var(--primary-200)',
                300: 'var(--primary-300)',
                400: 'var(--primary-400)',
                500: 'var(--primary-500)',
                600: 'var(--primary-600)',
                700: 'var(--primary-700)',
                800: 'var(--primary-800)',
                900: 'var(--primary-900)',
            },
            secondary: {
                50: 'var(--secondary-50)',
                100: 'var(--secondary-100)',
                200: 'var(--secondary-200)',
                300: 'var(--secondary-300)',
                400: 'var(--secondary-400)',
                500: 'var(--secondary-500)',
                600: 'var(--secondary-600)',
                700: 'var(--secondary-700)',
                800: 'var(--secondary-800)',
                900: 'var(--secondary-900)',
            },
            neutral: colors.gray,
            brand: {
                light: 'var(--brand-light)',
                DEFAULT: 'var(--brand-default)',
                dark: 'var(--brand-dark)',
            },
            warning: colors.amber,
            error: colors.red,
            info: colors.blue,
            success: colors.green,
        }
    },
    plugins: {
        'line-clamp': lineClamp,
        'forms': {
            ":global": forms
        },
        ...typography(),
        aspect: aspectRatio,
    }
}

export const customSheet = (target: any) => {
  const offset = target.cssRules.length;
  return {
    target,
    insert: (rule: any, index: any) => target.insertRule(rule, offset + index)
  }
}

export const parseTWColors = (config: any) => {
    for(let c in config?.theme?.extend?.colors) {
        if(typeof config.theme.extend.colors[c] === 'string' && config.theme.extend.colors[c].match('^colors.')) {
            let parts = config.theme.extend.colors[c].split('.');
            let curcolor: any = {...colors};

            parts.shift(); // removes the "colors" part of the object since that's a given
            for(let p in parts) {
                if(curcolor[parts[p]] !== undefined) curcolor = curcolor[parts[p]];
            }
            config.theme.extend.colors[c] = curcolor;
        }
    }
    return config;
}

export default twconfig;
