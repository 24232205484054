import React, { Component } from 'react';
import './App.scss';


import SwipeableViews from 'react-swipeable-views';
import PrivacyPolicy from './PrivacyPolicy';
import Lightboxes from './Lightboxes';
import PreLoader from './PreLoader';

import {
    AppBar,
    Tabs,
    Tab,
    createMuiTheme,
    ThemeProvider,
    CssBaseline,
    List,
    ListItem,
    Typography,
    Container,
    Paper,
    Box
} from '@material-ui/core';




import {
    cloneObject,
    mergeDeep,
    getDefaultProps
} from '../utils/functions';

import { AppProps, AppState } from '../types/app.types';
import { PlayerFrame } from '../types/api.types';
import classnames from 'classnames';

import { Router, Route } from 'react-router-dom';
import history, {historyTimeline} from '../utils/history';

class NGRPlayer extends Component<AppProps, AppState> {

    private api: any = null;
    private rootNexgen: any = null;
    private nexgen: any = null;
    private interval: any = 0;
    private waiting: any = [];
    private location: string = null;
    private frameProps: {[key: string]: any} = {};
    private privacyPath: string = '';

    private moduleList: {name: string, id: string}[] = [];

    state: AppState = {
        url: '',
        header: null,
        login: '',
        frames: [],
        hideHeader: false,
        darkMode: false,
        moduleName: '',
        moduleId: '',
        themeList: null,
        config: null,
        location: null,
        isSSO: false,
        profile: null,
        loading: false,
        windowWidth: window.outerWidth,
        tabs: {},
        token: null,
        showPrivacyPolicy: false,
        preventProtectedModuleRender: false
    }
   
    async componentDidMount() {

        let loading = true;
        var widthTimer: any = null;

        this.privacyPath = this.props.main.rootPath + '/privacy';
        this.rootNexgen = window.rootNexgen;
        this.rootNexgen.setParent(this);
        this.nexgen = window.nexgen;
        
        window.nexgen.togglePreloader(true);

       

        // console.log('nexgen', this.nexgen);

        // this.rootNexgen = new NexgenAPI(this, this.props.main.rootPath, this.props.AWSConfig);

        // window.name = "ngr-player";
        // this.privacyPath = this.rootNexgen.rootPath + '/privacy';

        // window.rootNexgen = this.rootNexgen;

        // this.nexgen = this.rootNexgen.createProxy(window, this.rootNexgen);
        // window.nexgen = this.nexgen;

        // this will be used by the app sign in to log in with SSO gateway
        let loc = window.nexgen.getCurrentLocation();
        if(!sessionStorage.getItem('initial-search-string') || sessionStorage.getItem('initial-search-string') === "") {
            sessionStorage.setItem('initial-search-string', loc.search);
        }
        if(!sessionStorage.getItem('initial-path') || sessionStorage.getItem('initial-path') === "") {
            sessionStorage.setItem('initial-path', loc.pathname + loc.hash);
        }

        // clear serviceworker cache to prevent sw issues we've been seeing
        try {
            this.nexgen.clearCache();
        } catch(e) {}

        var contentOnly = this.nexgen.getQueryParam('contentOnly') === 'true';
        let token = this.nexgen.getQueryParam('fdapptoken');
        let magicLink = this.nexgen.getQueryParam('ml');
        
        if(this.props.loggedIn) {
            this.nexgen.saveReportingEvent({
                activity: "load"
            })
        } else {
            await this.nexgen.clearAllSessionData();
        }

        if(token) {
            this.props.main.setState({inLegacyApp: true});
            this.setState({ token });
            this.nexgen.CognitoLogin(token, this.props.AWSConfig.awsexport.IdentityConfig);
        }

        if(magicLink) {
            this.nexgen.MagicLinkLogin(magicLink);
        }

        try {
            await this.checkAuth();
        } catch(e) {
        }

        let { themeList, config, profile } = await this.getConfigs();

        this.location = window.location.href;

        if(!('autoLogin' in config) || config.autoLogin === true) {
            this.nexgen.ssoGatewayLogin();
        }

        if(!this.props.loggedIn) { 
            
            loading = false;
            
        } 

        this.setState({ themeList, hideHeader: contentOnly, config, profile, loading });

        this.initWaiting(themeList);

        history.listen((location: any, action: any, ...other: any) => {


            // stop pending requests
            if(this.nexgen.client) {
                this.nexgen.client.stop();
            
                // need to throttle this or it will cause a SSO user to log out when navigating in a module
                this.nexgen.saveReportingEvent({
                    activity: "pathchange"
                });
            }
           
            this.setModuleList();
            // delay to avoid issues with state changes
            setTimeout(() => {
            
                if(action === "REPLACE") {
                    this.nexgen.callEvent('route_replace', { location, action, lastLocation: historyTimeline.lastLocation() });
                } else {
                    historyTimeline.push({...location, action});
                    this.nexgen.callEvent('route_change', { location, action, lastLocation: historyTimeline.lastLocation() });
                }
            }, 20);
        });

        // initial timeline push
        historyTimeline.push({...history.location, action: "LOAD"});


        window.addEventListener('resize', ()=> {
            clearTimeout(widthTimer);
            widthTimer = setTimeout(() => {
                this.setState({windowWidth: window.outerWidth})
            }, 300)
        });

        window.nexgen.on('media_capture', ({file, ...data}: any) => {
            if('mediaID' in data && data.mediaID in this.nexgen.mediaCaptureCallback) {
                this.nexgen.mediaCaptureCallback[data.mediaID](file, data);
                delete this.nexgen.mediaCaptureCallback[data.mediaID];
            }
        });

  
        window.nexgen.on('uploaded', (data: any) => {
            if('mediaID' in data && data.mediaID in this.nexgen.mediaUploadCallback) {
                this.nexgen.mediaUploadCallback[data.mediaID](data);
                delete this.nexgen.mediaUploadCallback[data.mediaID];
            }
        });

        this.nexgen.setModuleHashList();
      
        this.setModuleList();
    }

    /*fakeRequest = () => {
        return new Promise(resolve => setTimeout(resolve, 2500))
    };*/
    
    parseTheme(themeList: any, preference?: any) {
        let mode = 'light';
        var currentTheme: any = {};

        if('mui' in themeList && themeList.mui !== null) {
            currentTheme.type = 'mui';

            if('themePreference' in this.nexgen.config) {
                mode = this.nexgen.config.themePreference;
            } else {

                // TODO: Readd when dark mode works again

                // const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
                // if(!preference || preference === 'AUTO') {
                //     mode = (prefersDarkMode) ? 'dark' : 'light';
                // } else if(preference) {
                //     if(preference === 'DARK') mode = 'dark';
                //     if(preference === 'LIGHT') mode = 'light';
                // }
            }

            if('all' in themeList.mui.theme) currentTheme = cloneObject(themeList.mui.theme.all);
            else currentTheme = cloneObject(themeList.mui.theme);

            if(mode in themeList.mui.theme) {
                currentTheme = mergeDeep(currentTheme, themeList.mui.theme[mode]);
            }
            currentTheme.palette.type = mode;

            themeList.mui.merged = currentTheme;
            themeList.mui.build = createMuiTheme(currentTheme);

            // DEPRECATED: these will be removed after tailwind
            this.nexgen.theme = currentTheme;
            this.nexgen.muiTheme = themeList.mui.build;

        }

        return { themeList };
    }

    // Wait until the data layer (DG) is set up.
    // If user is not logged in, this returns immediately.
    // If DG is not configured, this returns immediately
    async waitForDataLayer(): Promise<void> {

        // DG not configured?
        if( !this.nexgen.AppConfig.dgraph?.token ){ return }

        const loggedIn = await this.nexgen.checkUserAuth()

        if( !loggedIn ){ return }

        let attempts = 0

        while(!this.nexgen.dgClient){

            await new Promise(resolve => setTimeout(resolve, 300))
            if( attempts++ > 30 ){
                console.error(`DGClient intialization timed out.`)
                break;
            }

        }
        
        return

    }


    async getTheme() {

    }

    async getConfigs() {
        let dir = 'public';
        var profile: any = null;
        var location: any = this.nexgen.getCurrentLocation();
        var themes = this.nexgen.AppConfig.themes;
        var themeList: any = {};

        if(!themes) {
            console.warn("Deployment manifest needs 'themes' specified");
            themes = ['default'];
        }

        if (this.props.loggedIn) {
            dir = 'protected';
        }

        if(location.pathname.match(this.privacyPath)) {
            dir = 'public';
        }
        
        for(var t in themes) {

            let configdir = dir;

            switch(themes[t]) {

                case "mui":
                    let muitheme = await this.nexgen.getAsset(`/assets/${dir}/app/themes/mui/theme.json`);
                    if(!muitheme && dir === 'protected') {
                        muitheme = await this.nexgen.getAsset(`/assets/public/app/themes/mui/theme.json`);
                        configdir = 'public'
                    }

                    themeList[themes[t]] = {
                        theme: muitheme,
                        dir: configdir
                    }
                    break;

                case "tailwind":
                    let twconfig = await this.nexgen.getAsset(`/assets/${dir}/app/themes/tailwind/config.json`);
                    
                    if(!twconfig && dir === 'protected') {
                        configdir = 'public';
                        twconfig = await this.nexgen.getAsset(`/assets/${configdir}/app/themes/tailwind/config.json`);
                    }

                    let twtheme = await this.nexgen.getAsset(`/assets/${configdir}/app/themes/tailwind/theme.json`);

                    themeList[themes[t]] = {
                        themeSets: twtheme,
                        config: twconfig,
                        dir: configdir
                    }
                    break;

                case "default":
                default:
                    let defaulttheme = await this.nexgen.getAsset(`/assets/${dir}/app/theme.json`);
                    if(!defaulttheme && dir === 'protected') {
                        defaulttheme = await this.nexgen.getAsset(`/assets/public/app/theme.json`);
                        configdir = 'public';
                    }

                    // mui is the default since initial player theme was built off of mui.
                    // this will override the "mui" instance if the "default" value comes after "mui" in the array.
                    // remove "default" or place it first in the array to prevent undesired outcomes
                    themeList.mui = {
                        theme: defaulttheme,
                        dir: configdir
                    }
            }
        }

        this.nexgen.themeList = themeList;


        let config = await this.nexgen.getAsset(`/assets/${dir}/app/config.yaml`);
        let themePreference = null;
        var currentTheme: any = {
            theme: null,
            muiTheme: null
        };

        if (this.props.loggedIn) {
            if (!config) {
                config = await this.nexgen.getAsset(`/assets/public/app/config.yaml`);
            }
         

        }
        

        if (!config) config = {};
        if(typeof config === 'string') {
            console.error('App config parsing error')
        } else if('vars' in config) {
            this.nexgen.setConfig(config.vars);
        }
      
        if('tailwind' in themeList) {
            let sheets: any = {};
            let initial = null;
            let twThemeSets = themeList.tailwind.themeSets;
            let defaultProps = await getDefaultProps();

            let findDefaultTheme = twThemeSets.find((t:any) => t.default === true);
            if(findDefaultTheme) initial = findDefaultTheme.name;

            for(let set of twThemeSets) {
                if(!initial) initial = set.name;
                sheets[set.name] = `:root{${parseStyleObject(set.palette)}}`;
            }
            // // set theme in nexgen (really sets an object that will be)
            themeList.tailwind.sheets = sheets;
            themeList.tailwind.defaultProps = defaultProps;
            themeList.tailwind.currentTheme = initial

            // apply the default theme to the main app
            await this.nexgen.applyTWTheme(initial);

        }

        
        function parseStyleObject(obj: any) {
            let str = '';
            for(var o in obj) {
                if(typeof obj[o] === 'object') {
                    str += parseStyleObject(obj[o]);
                } else if(o.match('^--')) {
                    str += `${o}:${obj[o]};`;
                }
            }
            return str;
        }

        
        try {
            if(this.props.loggedIn) {
                if(!("NGUsr" in this.props.AWSConfig) || this.props.AWSConfig.NGUsr === true) {
                    
                    profile = await this.nexgen.getCurrentUser("both", (newProfile: any) => {
                        let profile = this.state.profile;
                        if(profile && 'attStandard' in newProfile && newProfile.attStandard.themePreference !== profile.attStandard.themePreference) {
                            let updatedProfile = {...profile, ...newProfile};
                            let updatedTheme = this.parseTheme(themeList, updatedProfile.attStandard.themePreference);

                            this.setState({...updatedTheme, profile: updatedProfile}, () => {
                                // "theme" event will be reserved for mui theme
                                this.nexgen.callEvent('theme', updatedTheme.themeList.mui.merged);

                                this.nexgen.callEvent('theme_change', updatedTheme.themeList);
                            })

                        }

                        if(newProfile && 'attStandard' in newProfile) {
                            if(newProfile.attStandard.privacyPolicyAccepted !== true) {
                                let path = this.nexgen.getCurrentLocation().pathname;

                                if(path !== this.privacyPath) {
                                    this.setState({showPrivacyPolicy: true})
                                } else {
                                    this.setState({showPrivacyPolicy: false})
                                }
                            } else if(this.state.showPrivacyPolicy === true) {
                                this.setState({showPrivacyPolicy: false})
                            }
                        }

                        

                    });

                    if(profile.attStandard.privacyPolicyAccepted !== true) {
                        let path = this.nexgen.getCurrentLocation().pathname;
                        if(path !== this.privacyPath) {
                            this.setState({showPrivacyPolicy: true, preventProtectedModuleRender: true})
                        }
                    }
                    themePreference = profile ? profile.attStandard.themePreference : null;
                } else {
                    profile = await this.nexgen.getCurrentNGRProfile(['id','cognitoUsername', 'fdrURI', 'cognitoUserID','firstName','lastName', 'themePreference'], (data: any, ...rest: any) => {
                        let newProfile = 'items' in data ? data.items[0] : data[0];
                        let profile = this.state.profile;
                        if(profile && newProfile.themePreference !== profile.themePreference) {
                            let updatedTheme = this.parseTheme(themeList, newProfile ? newProfile.themePreference : null);
                            this.setState({...updatedTheme, profile: newProfile}, () => {
                                // "theme" event will be reserved for mui theme
                                this.nexgen.callEvent('theme', updatedTheme.themeList.mui.merged);

                                this.nexgen.callEvent('theme_change', updatedTheme.themeList);
                            })
                        }
                    });
                    this.nexgen.userID = 'fdrURI' in profile && profile.fdrURI && profile.fdrURI !== "" ? profile.fdrURI : profile.id;
                    themePreference = profile ? profile.themePreference : null;
                }

            }
        } catch(e) {}

        currentTheme = this.parseTheme(themeList, themePreference);

        return { ...currentTheme, config, profile }
    }


    async componentDidUpdate(oldprops: AppProps, oldstate: AppState) {

        if (oldprops.loggedIn !== this.props.loggedIn) {
            let { themeList, config, profile } = await this.getConfigs();
            this.setState({ themeList, config, profile });
            this.setModuleList();
        }
      
   
    }


    initWaiting(themeList: any) {

        (async() => {

            await this.waitForDataLayer()

            this.waiting.forEach((item: any) => {
                item.window.postMessage(item.content, item.src);
            })

        })()

    }

    setModuleList() {
        let route = this.nexgen.getRouteMatch();
        if(route.params.module === 'logout') {
            if(this.props.loggedIn) this.nexgen.logout();
        }

        let key = this.getLayoutKey(window);
        let layout = this.getLayoutMap(key);
        let list = Object.entries(layout?.modules ?? {}).map(([layoutKey, value]: [string, any]) => {
            if ((key === 'root' || layout.default === true) && layoutKey === 'content' && route.params.module) {
                value = {
                    name: route.params.module,
                    id: route.params.id
                }
            }
            return typeof value === 'object' ? value : {name: value, id: null}
        });

        this.moduleList = list;
    }

    async checkAuth() {
        if (this.props.loggedIn) {
            var forceLogin = this.nexgen.getQueryParam('forceLogin') === 'true';

            if(forceLogin) {
                await this.nexgen.logout(true, null);
                clearInterval(this.interval);
                return null;
            } else {
                try {
                    let { Auth } = await import("aws-amplify");
                    var user = await Auth.currentAuthenticatedUser();
                    return user;
                } catch (e) {
                    // this.nexgen.logout();
                    this.nexgen.logout(false);
                    clearInterval(this.interval);
                    return null;
                }
            }
        } else {
            return null;
        }
    }


    frameLoaded(props: any, data: any, e?: any) {

        console.log(`frameLoaded init.`)

        if (!e && data) {
            e = data;
            data = null;
        }

        let frame = e.target;
        let html = null;
        try {
            var doc = frame.contentDocument || frame.contentWindow.document;
            html = doc.body.innerHTML;
        } catch(e) {
            console.error(e)
        }

        if(!html) {
            return;
        }

        let fw = frame.contentWindow;
        
        if('createProxy' in window.nexgen) {
            fw.nexgen = window.nexgen.createProxy(frame, window.nexgen.top.nexgen)
        } else {
            fw.nexgen = window.nexgen;
        }

        fw.fd = window.nexgen.getFDBridge();

        if (props === null) props = { match: null, location: null };
  
        
        console.log(`About to sendInitParams`)

        this.sendInitParams(props, data, e.target);

    }


    sendInitParams(props: any, data: any, frame: any) {

        console.log(`sendInitParams init.`)

        let fw = frame.contentWindow;

        fw.nexgen = this.nexgen.createProxy(frame, this.nexgen);

        console.log(`sendInitParams init.`)

        let obj: any = {
            ngready: {
                name: frame.name,
                id: frame.id !== "" ? frame.id : window.name || null,
                url: {
                    match: props.match,
                    location: props.location
                }
            }
         };

        if (data) obj.ngready.data = data;
        // if (this.props.AppSyncUrl) {
        //     obj.ngready.endpoint = this.props.AppSyncUrl;
        // }

        // if(this.props.authData && (this.props.authData.signInUserSession && Object.keys(this.props.authData.signInUserSession).length)) {
        //     obj.ngready.authPayload = this.props.authData.signInUserSession.idToken.payload;
        // }

        (async () => {

            await this.waitForDataLayer()

            if (!this.state.themeList) {
                this.waiting.push({ content: obj, src: frame.src, window: fw });
              
            } else {
                obj.init = obj.ngready;
                fw.postMessage(obj, frame.src);
              
                const event = new CustomEvent('ngready', {detail: obj.ngready});
                fw.dispatchEvent(event);
            }
       
       
                   
           // window.nexgen.togglePreloader(false);

     
        })()

    }


    getLayoutKey(props: any) {
        let { config } = this.state;
        let mapping = 'mapping' in config ? config.mapping : null;
        let layouts = 'layouts' in config ? config.layouts : null;
        var key = 'root';
        let route = this.nexgen.matchPath(props.location.pathname, { path: `${this.props.main.rootPath}/:key?`});
        if(route && route.params.key) {
            key = route.params.key;
        }

        if (!layouts) {
            layouts = { full: { name: "main", style: { width: "100%", height: "100%", top: "0", left: "0" } } };
        }

        if (!mapping) {
            mapping = { root: [{ layout: "full", loggedIn: false, modules: { main: null } }] }
        }

        // if (!(key in mapping)) key = key + '/';
        if (!(key in mapping) && !(key + '/' in mapping)) {
            let defaultEntry = Object.entries(mapping).find(([key, value]: [string, any]) => {
                let val = value.find((v: any) => 'default' in v && v.default);
                return val;
            });
            if(defaultEntry && key in this.props.AWSConfig.moduleIndex) key = defaultEntry[0];
            else key = 'root';
        }
        return key
    }


    getLayoutMap(key: string) {
        let mapping = 'mapping' in this.state.config ? JSON.parse(JSON.stringify(this.state.config.mapping)) : null;
        if(!mapping) return null;

        let map = mapping[key];
        if (Array.isArray(mapping[key])) {
            map = mapping[key].find((m: any) => {
                let returnValue = true;
                if ('loggedIn' in m) {
                    returnValue = m.loggedIn === this.props.loggedIn;
                } else {
                    returnValue = true;
                }

                if(returnValue && 'layouts' in m) {
                    // select the layout based on the key being a min window width
                    let layouts = m.layouts.filter((layout: any, k: number) => {
                        return parseInt(layout.width) < this.state.windowWidth;
                    }).sort((a: any, b: any) => parseInt(b.width) - parseInt(a.width));
                    if(!layouts.length) layouts = [...m.layouts];
                    let layout = layouts[0];

                    m.layout = layout.layout;
                }
                return returnValue;
            });
        }
        return map;
    }

    changeTabIndex(frame: string, index: number) {
        let { tabs } = this.state;
        tabs[frame] = index;
        this.setState({tabs});
    }

    getLayoutStructure(layout: any, map: any, props: any, key: string) {
        let { tabs } = this.state;
        let structure: any = [];
        let { AWSConfig } = this.props;

        layout.forEach((frame: any) => {
            let name, id, framekey;
            if('tabs' in frame) {
                let framePanels = frame.tabs.elements.map((tab: any, index: number) => {
                    return {...tab, 
                            role: "tabpanel", 
                            id: `${frame.name}-panel-${index}`, 
                            value: tabs[frame.name], 
                            "aria-labelledBy": `${frame.name}-tab-${index}`,
                            index, 
                            hidden: frame.name in tabs ? tabs[frame.name] !== index : index === 0 ? false : true
                        }
                })
                let layoutResult = this.getLayoutStructure(framePanels, map, props, key);
                if('includeTabBar' in frame.tabs && frame.tabs.includeTabBar) {
                    structure.push(
                        <AppBar position='static' key={`${frame.name}-tabbar`}>
                            <Tabs
                                value={frame.name in tabs ? tabs[frame.name] : 0}
                                centered={true}
                                onChange={(e, val) => {
                                    this.changeTabIndex(frame.name, val);
                                }}
                                {...frame.tabs.tabBarStyles}
                            >
                                {frame.tabs.elements.map((element: any, index: number) => {
                                    if('hideTab' in element || !('label' in element)) return <div></div>;
                                    return <Tab 
                                        key={`${frame.name}-tab-${index}`} 
                                        id={`${frame.name}-tab-${index}`}
                                        label={element.label} 
                                        aria-controls={`${frame.name}-panel-${index}`} /> 
                                })}
                            </Tabs>
                        </AppBar>
                    )
                }
                structure.push(<SwipeableViews 
                                    key={frame.name}
                                    className={'swipeable-container'} 
                                    onChangeIndex={(index) => {
                                        this.changeTabIndex(frame.name, index)
                                    }}
                                    index={frame.name in tabs ? tabs[frame.name] : 0}
                                    style={frame.style}>
                                    {layoutResult}
                                </SwipeableViews>)
                return;
            }

            if(typeof map.modules[frame.name] === 'object' || Array.isArray(map.modules[frame.name])) {
                let keep = false;
                if(Array.isArray(map.modules[frame.name])) {

                    // Array.some breaks loop when true is returned
                    map.modules[frame.name].some((fitem: any) => {
                        // if already found, don't check any more module filters
                        keep = ('filter' in fitem) ? this.nexgen.matchFilter(fitem.filter, this.state.profile) : true;
                        if(keep) map.modules[frame.name] = fitem;
                        return keep;
                    })
                } else {
                    keep = (map.modules[frame.name] && 'filter' in map.modules[frame.name]) ? this.nexgen.matchFilter(map.modules[frame.name].filter, this.state.profile) : true;
                }
                if(!keep) return;
            }

            if (frame.name in map.modules) {
                name = typeof map.modules[frame.name] === 'string' || map.modules[frame.name] === null ? map.modules[frame.name] : map.modules[frame.name].name;
                id = typeof map.modules[frame.name] === 'string' || map.modules[frame.name] === null ? null : map.modules[frame.name].id;
            }
            
            if ((key === 'root' || map.default === true) && (frame.name === 'content' || frame.type === 'content') && props.match.params.module) {

                if(props.match.params.module in AWSConfig.moduleIndex) {
                    name = props.match.params.module;
                    id = props.match.params.id;
                }

            }
            
            if(((map.useID === true && (frame.name === 'content' || frame.type === 'content')) || frame.useID === true) && !id) {
                id = props.match.params.id;
            }

            if(!name) name = frame.name;

            framekey = name;
            if(!id) id = 'default';
            
            framekey += `.${id}`;

            this.frameProps[framekey] = props;

            if((('element' in frame && frame.element !== 'iframe') || (name in AWSConfig.moduleIndex && AWSConfig.moduleIndex[name].match('^/?protected'))) && this.state.preventProtectedModuleRender) {
                return;
            }

            if (!('element' in frame)) {
                if(name in AWSConfig.moduleIndex) {   
                    structure.push(<iframe
                        className={'structure-element structure-iframe'}
                        key={framekey}
                        title={name}
                        name={name}
                        id={id}
                        style={frame.style}
                        src={`/${AWSConfig.moduleIndex[name]}/index.html`}
                        onLoad={this.frameLoaded.bind(this, props)}
                    />)
                    }
            } else {
                let Element = frame.element;
                let attributes = frame.attributes || {};
                if ('content' in frame) {
                    attributes.dangerouslySetInnerHTML = { __html: frame.content }
                }
                if (frame.element === 'iframe') {
                    attributes.onLoad = () => {
                        // console.log('loaded');
                    }
                }
                structure.push(<Element
                    className={'structure-element'}
                    key={framekey}
                    name={name}
                    id={id}
                    style={frame.style}
                    {...attributes}
                />)
            }
        });
        return structure;
    }

    render() {
        //if (this.state.loading) {
        //    return null; //app is not ready (fake request is in process)
       // }
        
        let { frames, config, themeList, showPrivacyPolicy, loading } = this.state;

        if (!config || !themeList) return null;
        window.nexgen.initTWTheme();

        let muiTheme = themeList.mui?.build ?? undefined;
        
        let Provider = muiTheme ? ThemeProvider : React.Fragment;
        let providerProps: any = muiTheme ? { theme: muiTheme } : {}

        return (
            <Provider {...providerProps}>
                {muiTheme && <CssBaseline />}
            <Router history={history}>
                <div className={classnames({
                    'App': true,
                    'hide-header': this.state.hideHeader,
                    'darkMode': this.state.darkMode,

                })}>
                   <PreLoader  />

                    {('ngr-layouts' in this.props.AWSConfig.moduleIndex) ?
                       <iframe title={'ngr-layouts'} key={'ngr-layouts'} id={'ngr-layouts'} name={'ngr-layouts'} src={`/${this.props.AWSConfig.moduleIndex['ngr-layouts']}/index.html`} onLoad={this.frameLoaded.bind(this, null, null)} ></iframe>
                        :


                        <Route path={`${this.props.main.rootPath}/:module?/:id?`} render={(props: any) => {

                            if(this.props.loggedIn && props.match.params.module === 'versions') {
                                // lists versions from the deployment manifest
                                return <Box style={{position: "absolute", width: "100%", height: "100%"}}>
                                        <Container>
                                            <Paper>
                                                <Box p={2}>
                                                    {Object.entries(this.props.AWSConfig.versions).map(([key,version]: [string, any]) => {
                                                        return <Container>
                                                                    <Typography variant="h5" style={{textAlign: 'left'}}>{key}</Typography>
                                                                    <List>
                                                                        <ListItem>Version: {version.version}</ListItem>
                                                                        <ListItem>Hash: {version.hash}</ListItem>
                                                                    </List>
                                                                </Container>
                                                    })}
                                                </Box>
                                            </Paper>
                                        </Container>
                                    </Box>
                            }

                            if('ngr-layouts' in this.props.AWSConfig.moduleIndex) {
                                return <iframe title={'ngr-layouts'} key={'ngr-layouts'} id={'ngr-layouts'} name={'ngr-layouts'} src={`/${this.props.AWSConfig.moduleIndex['ngr-layouts']}/index.html`} onLoad={this.frameLoaded.bind(this, null, null)} ></iframe>
                            }
                         
                            let layouts = 'layouts' in config ? this.state.config.layouts : null;
                            let key = this.getLayoutKey(props);
                            let map = this.getLayoutMap(key)
                            let layoutFrames = layouts[map.layout];

                            return this.getLayoutStructure(layoutFrames, map, props, key);
                        }} />

                    }

                    <PrivacyPolicy 
                        open={showPrivacyPolicy && this.state.preventProtectedModuleRender}
                        options={{
                            privacyPath: this.privacyPath
                        }}
                        onCancel={() => {
                            this.setState({
                                showPrivacyPolicy: false
                            }, () =>{
                                window.nexgen.logout();
                            })
                        }}
                        onAccept={() => {
                            this.setState({
                                preventProtectedModuleRender: false
                            })
                            this.nexgen.updateUserProfile({privacyPolicyAccepted: true});
                        }}
                        />
                    <Lightboxes
                        frames={frames}
                        themeList={themeList}
                        frameLoaded={this.frameLoaded.bind(this)}
                        />

                </div>
            </Router>
            </Provider>
        );
    }
}


export default NGRPlayer;