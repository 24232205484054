import { InitModule,ConfigOptions, ProxyModuleData, KeyParams, GetAssetOptions, GetAssetResult, UploadOptions, MediaCaptureType } from '../types/api.types';
import {
    parseValues
} from '../utils/functions';

//@ts-ignore
import yaml from 'yaml';

const AssetMethods = {

    mediaCaptureCallback: {},
    mediaUploadCallback: {},

    getConfig: async function (moduleObject: InitModule | string, options: string | ConfigOptions = {}) {

        let name: string;
        let id: string;
        let key: string;
        let getStore: any = null;
        let data: any = null;

        
        if(!moduleObject) {
            let data: ProxyModuleData = this.getProxyModuleData();
            name = data.name;
            id = data.id;
        } else if (typeof moduleObject === 'object') {
            if ('currentModule' in moduleObject) {
                console.warn('currentModule and moduleID are deprecated, please start using id and name');
                id = moduleObject.moduleID;
                name = moduleObject.currentModule;
            } else {
                id = moduleObject.id;
                name = moduleObject.name;
                key = moduleObject.key;
            }
        } else {
            name = moduleObject;
            id = (options as string);
        }

        getStore = await this.getAsset(`/assets/protected/${name}/config.yaml`);

        if (!getStore && id) {
            // check for specific id config
            try {
                getStore = await this.getAsset(`/assets/protected/${name}/${id}/config.yaml`);
            } catch(e) {}
        }

        if (!getStore) {
            try {
                getStore = await this.getAsset(`/assets/protected/${name}/config.json`);
            } catch(e) {}
        }

        if (!getStore) {
            console.warn('Update the module configuration file to be config.yaml');
            try {
                getStore = await this.getAsset(`/assets/protected/${name}/storevalues.yaml`);
            } catch(e) {}
        }

        data = getStore;

        if (id) {
            if (id in getStore) data = getStore[id];
            if ((options as ConfigOptions).strict) {
                if (!(id in getStore)) data = null;
            }
        }

        if (key && data) {
            return data[key];
        } else {
            return data;
        }
    },

    getLogo: function (value?: string, moduleName?: string) {

        let config = this.parent.state.config;
        let logo = null;

        let valueVar = value.match(/\{([a-zA-Z0-9.-_]*)\}/);
        if (valueVar) {
            let parts = valueVar[1].split('.');
            let getValue: any = global;

            for (var p in parts) {
                if (parts[p] in getValue) getValue = getValue[parts[p]];
                else break;
            }
            if (typeof getValue === 'string') value = getValue;
        }

        if ('logos' in config) {

            for (var key in config.logos) {
                if (value.match(key)) {
                    if (typeof config.logos[key] === 'string') {
                        logo = config.logos[key];
                    } else {
                        if (moduleName && moduleName in config.logos[key]) {
                            logo = config.logos[key][moduleName];
                        } else if ('default' in config.logos[key]) {
                            logo = config.logos[key].default;
                        }
                    }
                }
            }

            if (!logo) {
                logo = config.logos.default;
            }
        }

        return logo;

    },

    getAsset: async function(filename: string | KeyParams | KeyParams[], options: GetAssetOptions = {}) {

        let result,
            type = null,
            partition = this.getPartitionType(),
            section = this.getSection(),
            ngrPartition = this.getPartitionType(true),
            controller = null,
            controllerID = this.getUUID(),
            instanceID = this.getProxyInstanceID();

        if(!(instanceID in this.signalControllers)) this.signalControllers[instanceID] = {};

        try {
            controller = new AbortController();
            this.signalControllers[instanceID][controllerID] = controller;
        } catch(e) {}

        // TODO: phase out the object and array versions of this method
        if (typeof filename !== 'string') {
            if (Array.isArray(filename)) {
                console.warn('This use-case of "getAsset" will be deprecated in a future release. Please use "getAssets" if you want an array of assets')
            } else {
                console.warn('Please update "getAsset": Expected 2 arguments but got 1.');
                filename = [filename];
            }
            return await this.getAssets(filename);
        } else {
            type = filename.substr(filename.lastIndexOf('.') + 1);

            if ('type' in options) {
                type = options.type;
            }
        }

        // Proper method usage from here

        try {
            let headers = 'headers' in options ? options.headers : {};

            var res = await fetch((filename as string), {signal: controller.signal, headers});

            delete this.signalControllers[instanceID][controllerID];

            if (res.status !== 200) {
                console.error('Error getting asset', res.url);
                return null;
            }

        } catch (e) { }

        let replacements = {
            'section': section,
            'fdr_partition': partition,
            'ngr_partition': ngrPartition,
            'full_partition': this.getPartition(),
            'partition': partition
        }

        switch (type) {
            case "json":
                try {
                    result = await res.text();
                    result = JSON.parse(parseValues(result, replacements));
                } catch (e) { }
                break;

            case "yaml":
            case "yml":
                try {
                    result = await res.text();
                    result = yaml.parse(parseValues(result, replacements));
                } catch (e) { }
                break;
            case "blob":
                result = await res.blob();
                break;

            case "arrayBuffer":
                result = await res.arrayBuffer();
                break;

            default:
                result = await res.text();
                break;
        }

        return result;

    },

    getAssets: async function (assets: KeyParams[]) {

        var getAssetResult: GetAssetResult = {};

        for (var a in assets) {
            let param = assets[a];
            let result = await this.getAsset(param.filename, { type: param.type });
            if ('name' in param) getAssetResult[param.name] = result;
            else if (assets.length === 1) getAssetResult = result;
            else getAssetResult[a] = result;
        }

        return getAssetResult;
    },


    parseYaml: async function (text: string) {
        return yaml.parse(text);
    },

    openMediaCapture: async function(options: MediaCaptureType = {}) {

        let mediaID = this.getUUID();
        let styles: any = {
            background: {
                background: 'rgba(0,0,0,0.5)'
            },
            box: {
                width: "100%",
                height: "100%"
            }
        }
 
        if('captureCallback' in options) this.mediaCaptureCallback[mediaID] = options.captureCallback;
        if('uploadCallback' in options) this.mediaUploadCallback[mediaID] = options.uploadCallback;
        if('lightboxStyles' in options) styles = {...styles, ...options.lightboxStyles};
        let mediaPlayerOptions = ('options' in options) ? options.options : {}

        this.openLightbox({
            module: 'module' in options ? options.module : 'ngr-media-capture',
            id: 'id' in options ? options.id : 'default',
            styles,
            options: {
                closeButton: true
            },
            params: {
                uploadOnSave: 'uploadOnSave' in options ? options.uploadOnSave : false,
                mediaID,
                ...mediaPlayerOptions
            }
        });

        return mediaID;
    },

    upload: async function(file: File, filename: string | UploadOptions, options?: UploadOptions) {

        return new Promise(async (resolve, reject) => {

            if(typeof filename !== 'string') {
                options = filename;
                filename = ('filename' in options) ? options.filename : file.name;
            }

            if(!options) options = {};

            let level = 'level' in options ? options.level : 'protected',
                user = 'NGUsr' in this.AppConfig && this.AppConfig.NGUsr === true ? await this.getCurrentUser('ngusr') : await this.getCurrentNGRProfile(['id']),
                basePath = '/storage',
                uploadPath = 'uploadPath' in options ? options.uploadPath : '';

            if(!user || !('id' in user)) {
                console.error('User profile error:', user)
                return;
            }
            
            if(uploadPath === "") {
                uploadPath = `${basePath}/${user.id}/${level}`;
            }

            // let uploadFile = await fetch(`${uploadPath}/${filename}`, {
            //     method: "PUT",
            //     body: file,
            //     headers: new Headers({
            //         'Content-Type': file.type
            //     })
            // });

            // console.log('upload progress', uploadFile);

            // if (uploadFile.status === 200) {
            //     return {
            //         file: `${uploadPath}/${filename}`
            //     }
            // } else {
            //     return await uploadFile.json();
            // }
 
            let request = new XMLHttpRequest();
            request.open('PUT', `${uploadPath}/${filename}`);
            request.setRequestHeader('Content-Type', file.type);

            request.upload.addEventListener('progress', (e) => {
                let percent_completed = (e.loaded / e.total)*100;
                if('uploadProgress' in options) options.uploadProgress(percent_completed);

                if(percent_completed === 100 && `mediaID` in options) {
                    this.callEvent('uploaded', {path: `${uploadPath}/${filename}`, mediaID: options.mediaID})
                }
            });
            request.send(file);

            request.addEventListener('load', (e) => {

                if(request.status === 200) {
                    resolve({
                        status: request.status,
                        response: request.response,
                        file: `${uploadPath}/${filename}`
                    })
                } else {
                    reject({
                        status: request.status,
                        error: request.response
                    });
                }
            });

        });

    }
}

export default AssetMethods;